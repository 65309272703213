#page-music {
    height: 100vh;
    padding: 0 var(--padding);
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: $mobile) {
        height: 125vh;
    }

    .artwork {
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;

        @media (max-width: $mobile) {
            flex-direction: column;
            margin-left: 0;
        }

        .artwork-item {
            width: 40%;

            @media (max-width: $mobile) {
                width: 100%;
                margin: 0;
            }

            img {
                width: 100%;
                height: auto;
                transform: scale(1);
                transition: all 0.3s ease-in-out;
            }

            a {
                &:hover {
                    img {
                        transform: scale(1.05);
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
    }

    h1 {
        text-transform: uppercase;
        font-size: 8rem;
        line-height: 0.9em;
        mix-blend-mode: difference;
        position: relative;
        z-index: 1;

        @media (max-width: $mobile) {
            font-size: 5rem;
        }

        span {
            display: block;
        } 
    }

    div {
        margin-top: 50px;
        margin-left: 14px;
        font-size: 20px;
        position: relative;
        z-index: 1;
    }

    a {
        color: white;
        text-transform: uppercase;
        text-decoration: none;
    }
}