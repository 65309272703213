@font-face {
    font-family: 'Space Mono';
    src: url('static/fonts/SpaceMono-Bold.woff2') format('woff2'),
        url('static/fonts/SpaceMono-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Space Mono';
    src: url('static/fonts/SpaceMono-Regular.woff2') format('woff2'),
        url('static/fonts/SpaceMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


h1, h2, h3, h4 {
    font-family: 'Space Mono', system-ui, sans-serif;
    font-weight: normal;
    margin: 0;
}

h2 {
    font-size: 4em;
    margin-bottom: 0.3em;
    font-weight: normal;
}

p {
    font-family: 'Space Mono', system-ui, sans-serif;
    font-weight: normal;
    margin: 0;
    font-size: 0.9em;
    line-height: 1.4em;
    margin-bottom: 1.2em;

    &:last-child {
        margin-bottom: 0;
    }
}