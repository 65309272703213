nav {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    align-items: center;
    padding: 30px var(--padding);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 2;
    font-family: 'Space Mono', system-ui, sans-serif;

    &.is-active {
        .btn-menu {
            opacity: 0;
            pointer-events: none;

            &:hover {
                opacity: 0;
            }
        }

        .site-navigation-list  {
            @media (max-width: $tablet) {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                padding: 20px; 
                background:rgba(0,0,0,.9);
                justify-content: center;
            }

            li {
                opacity: 1;
                pointer-events: all;
                animation: fadeIn 0.3s 1;
                animation-fill-mode: forwards;

                @media (max-width: $tablet) {
                    &:last-child {
                        a {
                            border-right: none !important;
                        }
                    }
                }

                a {
                    @media (max-width: $tablet) {
                        border: none;
                        width: 100%;
                        text-align: center;
                        font-size: 2rem;
                    }
                }
            }
        }

    }

    a {
        color: white;
        text-decoration: none;
        font-weight: normal;
    }

    svg.logo {
        width: 90px;
    }

    .btn-menu {
        width: 40px;
        height: 40px;
        display: block;
        position: fixed;
        top: 35px;
        right: var(--padding);
        cursor: pointer;

        &:hover {
            opacity: .8;
        }

        svg {
            width: 100%;
            height: 100%;
            fill: white;
        }
    }

    .site-navigation-list {
        display: flex;
        
        li {
            opacity: 0;
            pointer-events: none;
            transition: .2s all ease-in-out;

            a {
                padding: .8em 2em;
                display: inline-block;
                border: 1px solid white;
                border-right: none;
                text-transform: uppercase;
                color: white;
                text-decoration: none;
                font-family: 'Space Mono', system-ui, sans-serif !important;

                &:hover {
                    background: white;
                    color: black;
                }
            }

            &:last-child {
                a {
                    border-right: 1px solid white;
                }
            }
        }
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}