@import './style/variables.scss';
@import './style/reset.scss';
@import './style/typography.scss';
@import './style/nav.scss';
@import './style/footer.scss';
@import './style/backgrounds.scss';
@import './style/lightgallery.scss';


@import './style/pages/home.scss';
@import './style/pages/about.scss';
@import './style/pages/radio.scss';
@import './style/pages/upcomingevents.scss';
@import './style/pages/music.scss';
@import './style/pages/contact.scss';

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
    box-sizing: border-box;
    -webkit-appearance: none !important;
}

.subscribeFormPopup {
    #sub-thanks-popup {
        display: none;
    }
}

#signup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, .5);
    justify-content: center;
    align-items: center;
    display: none;
    transition: .8s opacity ease;

    h2 {
        @media (max-width: $tablet) {
            font-size: 3em;
        }
    }

    &.active {
        display: flex;
        transition: .8s opacity ease;
    }

    .inner {
        width: 100%;
        max-width: 600px;
        background: black;
        border: 1px solid #ccc;
        padding: 40px;
        position: relative;
    }

    #mce-EMAIL {
        background: #333;
        color: white;
        padding: 0.8em;
        border: none;
        width: 100%;
        margin-bottom: 15px;
        font-family: "Space Mono", system-ui, sans-serif;
    }

    .btn-close {
        background: none;
        border: none;
        width: 30px;
        height: 30px;
        right: 0;
        position: absolute;
        top: 20px;
        right: 10px;

        @media (max-width: $tablet) {
            width: 45px;
            height: 45px;
        }

        &:hover {
            opacity: .5;
            cursor: pointer;
        }

        svg {
            stroke: white;
        }
    }

    input[type="submit"] {
        background: #FF5E5A;
        color: black;
        padding: 0.8em;
        border: none;
        width: 20%;
        cursor: pointer;
        font-weight: 500;
        justify-content: center;
        align-items: center;
        display: flex;
        text-transform: uppercase;

        @media (max-width: $tablet) {
            width: 100%;
        }

        &:hover {
            background: #e0524e;
        }
    }

    ::placeholder {
        color: white;
        opacity: 1; /* Firefox */
      }
      
    ::-ms-input-placeholder { /* Edge 12 -18 */
        color: white;
    }
}


html {
    --color-background: black;
    --padding: 60px;
    background-color: var(--color-background);
    height: 100%;
    background: black;

    @media (max-width: $mobile) {
        --padding: 20px;
    }
}


body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
    overflow-x: hidden;
    background: black;
    font-family: system-ui, sans-serif, 'Space Mono';
    font-weight: normal;
}

main {
    >div {
        min-height: 100vh;
    }
}

.site-wrapper, .site-container {
    min-height: 100vh;

    &.is-leaving {
        opacity: 0;
    }
}


body {
    background: black;
    color: white;

    &.is-locked {
        @media (max-width: $mobile) {
            overflow: hidden;
        }
    }
}

.background-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;

    >div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        animation: pulse 15s ease-in-out infinite;
    }

    @keyframes pulse {
        0% {
         transform: scale(1, 1);
        }
    
        50% {
         transform: scale(1.1, 1.1);
        }
    
        100% {
        transform: scale(1, 1);
        }
    }
}

.page {
    z-index: 1;
    position: relative;
    padding: 0 var(--padding);
    height: 100%;
}


canvas.webgl {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
}

.backgroundImage {
    display: none;
}


.preloader {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: 1090;
    pointer-events: none;
    user-select: none;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    background: black;
    transition: opacity 0.4s ease;

    img {
        width: 40px;
    }

    &.show {
        opacity: 1;
        transition: opacity 0.4s ease;
    }

    @media screen and (orientation:landscape)
        and (min-device-width: 319px) 
        and (max-device-width: $mobile) {
            display: none;
    }
}
