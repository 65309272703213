#page-contact {
    padding: 0 var(--padding);
    display: flex;
    justify-content: flex-start;
    padding-top: 120px;
    padding-left: 50px;
    flex-direction: column;

    @media (max-width: $mobile) {
        padding-left: 20px;
    }

    form {
        width: 100%;
        max-width: 550px;
    }

    #thankyou {
        font-family: 'Space Mono', system-ui, sans-serif;
        margin: 0;
        display: none;

        div {
            margin: 0;
            margin-top: 5px;
        }
    }

    button[type="submit"] {
        background: #FF5E5A;
        color: black;
        padding: .8em;
        border: none;
        width: 100px;
        cursor: pointer;
        font-weight: 500;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-top: 30px;
        margin-bottom: 100px;

        &:hover {
            background: black;

            span {
                background: white;
            }

            em {
                color: white !important;
            }
        }

        span {
            background: black;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            left: -5px;
            position: relative;
            top: -1px;
        }

        em {
            display: block;
        }
    }

    formfield {
        display: block;
        margin-bottom: 10px;
        font-family: 'Space Mono', system-ui, sans-serif;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 14px;

        ::placeholder {
            color: white;
            opacity: 1; /* Firefox */
          }
          
          ::-ms-input-placeholder { /* Edge 12 -18 */
            color: white;
          }

        .input-text {
            display: block;
            width: 100%;
            margin: 10px 0;
            padding: 15px;
            font-family: 'Space Mono', system-ui, sans-serif;
            font-weight: normal;
            background: black;
            color: white;
            border: none;
        }
    }

    h1 {
        text-transform: uppercase;
        font-size: 8rem;
        line-height: 0.9em;
        mix-blend-mode: difference;
        position: relative;
        z-index: 1;

        @media (max-width: $mobile) {
            font-size: 4rem;
        }

        span {
            display: block;
        } 
    }

    h3 {
        display: block;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    a {
        font-size: 16px;
        display: block;
        margin-bottom: 5px;
    }

    p {
        margin: 0;
    }

    br {
        display: none;
    }

    div {
        margin-top: 50px;
        margin-left: 5px;
        font-size: 20px;
        position: relative;
        z-index: 1;

       
    }

    a {
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        margin-bottom: 0;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

        &:hover {
            opacity: .5;
        }
    }
}