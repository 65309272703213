.events-wrapper {
    .background-container {
        position: fixed;
    }
}

#page-upcomingevents {
    min-height: 100vh;
    padding: 0 var(--padding);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    @media (max-width: 1220px) {
        min-height: auto;
    }

    

    > div {
        width: 100%;
        display: flex;

        @media (max-width: 1220px) {
            flex-direction: column;
        }
    }

    #contentBlockPastEventsLeft {
        width: 30%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 150px;

        @media (max-width: 1220px) {
            height: auto;
        }

        h1 {
            font-size: 3rem;
            line-height: 0.9em;
            margin-bottom: .5em;
        }

        p {
            line-height: 1.3em;
            font-size: 14px;
        }
    }

    table {
        td {
            padding: 4px;
            text-transform: uppercase;

            &:first-child {
                @media (max-width: $mobile) {
                   width: 100px;
                } 
            }

            &:last-child {
                text-align: right;
            }

            button {
                background: #333;
                font-size: 10px;
                color: #fff;
                text-transform: uppercase;
                border: none;
                padding: 4px;
                cursor: pointer;

                &:hover {
                    opacity: .5;
                }

                &.info {
                    background: #9D9C9A;
                    color: #000;
                }
            }
        }
    }

    .listings {
        li {
            text-transform: uppercase;
            justify-content: space-between;
            display: flex;
            margin-bottom: 10px;

            @media (max-width: $mobile) {
                font-size: 13px;
             }

            .country {
                @media (max-width: $mobile) {
                   display: none;
                }
            }

            div {
                text-align: left;
            }

            div:first-child {
                width: 80px;

                @media (max-width: $mobile) {
                    width: 100px;
                }
            }

            div:nth-child(2) {
                width: 250px;
                line-height: 1.2em;
                margin-right: 30px;

                @media (max-width: $mobile) {
                    width: 210px;
                }
            }
            div:nth-child(3) {
                width: 50px;
                margin-right: 30px;
            }
            div:nth-child(4) {
                width: 250px;
                margin-right: 30px;
            }
            div:nth-child(4) {
                width: 140px;

                @media (max-width: $mobile) {
                    width: 160px;
                    display: flex;
                    justify-content: flex-end;
                 }
            }

            button {
                background: #333;
                font-size: 10px;
                color: #fff;
                text-transform: uppercase;
                border: none;
                padding: 4px;
                cursor: pointer;

                &:hover {
                    opacity: .5;
                }

                &.info {
                    background: #9D9C9A;
                    color: #000;
                }
            }
        }
    }

    #contentBlockPastEventsListings {
        width: 70%;

        @media (max-width: $mobile) {
            width: 100%;
        }

        >div {
            padding-top: 150px;
            padding-bottom: 150px;

            @media (max-width: 1220px) {
                padding-top: 50px;
            }
        }
    }

   img.bandsintown {
        position: fixed;
        bottom: var(--padding);
        left: var(--padding);
        max-width: 125px;
   }
}